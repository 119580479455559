import bocchi from "./assets/bocchi.jpg"
import demon from "./assets/demon.jpg"
import takagi from "./assets/takagi.jpeg"
import johan from "./assets/johan.png"
import oregairu from "./assets/oregairu.webp"
import rem from "./assets/rem.webp"
import sikimori from "./assets/sikimori.jpg"
import yagami from "./assets/yagami.jpeg"
import kaguya from "./assets/kaguya.jpeg"
import jonny from "./assets/jonny.png"
import eren from "./assets/eren.jpg"
import friend from "./assets/friend.webp"
import brook from "./assets/brook.gif"
import madara from "./assets/madara.webp"
import kake from "./assets/kake.jpg"
import tomo from "./assets/tomo.jpg"


const Animelist = [
    {
        image : bocchi,
        op1 : "Kaguya sama love is war",
        op2 : "Bocchi the rock",
        op3 : "komi can't communicate",
        op4 : "The moon is beautifull",
        Ans : "Bocchi the rock"
    },

    {
        image : tomo,
        op1 : "Kaguya sama love is war",
        op2 : "Monster",
        op3 : "Tomodachi game",
        op4 : "20th century boys",
        Ans : "Tomodachi game"
    },
    {
        image : kaguya,
        op1 : "Kaguya sama love is war",
        op2 : "Oregairu",
        op3 : "komi can't communicate",
        op4 : "Kimi no nawa",
        Ans : "Kaguya sama love is war"
    },
    {
        image : eren,
        op1 : "Fairy tail",
        op2 : "Oregairu",
        op3 : "Attack on titan",
        op4 : "Bleach",
        Ans : "Attack on titan"
    },
    {
        image : jonny,
        op1 : "Jojo bizzare adventure",
        op2 : "Attack on titan",
        op3 : "One piece",
        op4 : "Naruto",
        Ans : "Jojo bizzare adventure"
    },

    {
        image : kake,
        op1 : "Classroom of the elite",
        op2 : "Death note",
        op3 : "Monster",
        op4 : "Kakegrurui",
        Ans : "Kakegrurui"
    },
    {
        image : rem,
        op1 : "Classroom of the elite",
        op2 : "Death parade",
        op3 : "Re-zero",
        op4 : "Kakegrurui",
        Ans : "Re-zero"
    },
    {
        image : takagi,
        op1 : "Teaching-master takagi-san",
        op2 : "slient voice",
        op3 : "kubo won't be invisible",
        op4 : "Jojo bizzare advanture",
        Ans : "Teaching-master takagi-san"
    },
    {
        image : madara,
        op1 : "Bleach",
        op2 : "Naruto",
        op3 : "Re-zero",
        op4 : "One piece",
        Ans : "Naruto"
    },
    {
        image : oregairu,
        op1 : "Bleach",
        op2 : "oregairu",
        op3 : "Re-zero",
        op4 : "Classroom of the elite",
        Ans : "oregairu"
    },
    {
        image : demon,
        op1 : "Bakemonokatari",
        op2 : "Demon slayer",
        op3 : "Re-zero",
        op4 : "Fairy tail",
        Ans : "Demon slayer"
    },
    {
        image :sikimori,
        op1 : "Weathering with your",
        op2 : "Kimi no nawa",
        op3 : "Shikimori is not just a cutie",
        op4 : "The moon is beautiful",
        Ans : "Shikimori is not just a cutie"
    },
    {
        image : johan,
        op1 : "Death parade",
        op2 : "Demon slayer",
        op3 : "Monster",
        op4 : "Death note",
        Ans : "Monster"
    },
    {
        image : yagami,
        op1 : "Death parade",
        op2 : "Classroom of the elite",
        op3 : "Kakegrurui",
        op4 : "Sword Art Online",
        Ans : "Classroom of the elite"
    },
    {
        image : brook,
        op1 : "Naruto",
        op2 : "One piece",
        op3 : "Bleach",
        op4 : "Sword Art Online",
        Ans : "One piece"
    },
    {
        image : friend,
        op1 : "20th century boys",
        op2 : "sword art online",
        op3 : "Bleach",
        op4 : "Hunter x Hunter",
        Ans : "20th century boys"
    }
]


export default Animelist;