import './App.css';
import "./Anime.scss"
import "./Media.scss"
import Animelist from "./AnimeApi"
import music from "./assets/music.mp3"
import { useState, useEffect } from 'react';
function App() {
  const [currentImageNumber, setCurrentImageNumber] = useState(0)
  const [error, setError] = useState(null)
  const [isCorrect, setIsCorrect] = useState(false);

  const handleClick = (event) => {
    const answer = event.target.textContent;
    const correctAnswer = Animelist[currentImageNumber].Ans;
    if (answer === correctAnswer) {
      setIsCorrect(true);
      setError("Right Answer!! 😃")
      setTimeout(() => {
        setError(null)
        if(currentImageNumber >= 15){
          let a = prompt("You give all the right answer.You fucking weeb!! Do you want to play again?")
          if(a === "yes" && "y"){
            setCurrentImageNumber(0)
            localStorage.setItem("currentImageNumber", 0);
          }else{
            alert("Then close this shit and give feedback to gaurab sir")
            localStorage.setItem("currentImageNumber", 0);
          }

        }else{
          setCurrentImageNumber(currentImageNumber + 1)
          localStorage.setItem("currentImageNumber", currentImageNumber + 1);
        }
        
      }, 2000);
      // currentImageNumber > 16 && alert('Good you completd the quize')
    } else {
      setIsCorrect(false);
      setError("Wrong answer!! Please try again 😥")
      setTimeout(() => {
        setError(null)
        setCurrentImageNumber(0)
        localStorage.setItem("currentImageNumber", 0);
      }, 2000);
    }
  }
  useEffect(() => {
    const currentImageNumberFromLocalStorage = localStorage.getItem("currentImageNumber");
    if (currentImageNumberFromLocalStorage) {
      setCurrentImageNumber(parseInt(currentImageNumberFromLocalStorage));
    }
  }, []);
  

  return (
    <>
      <div className="main-container">
      <audio autoPlay src={music}>
              </audio>
          <div className="anime-content">
            <div className="heading">
              <h1>Guess Anime from character</h1>
            </div>
            {Animelist ? (Animelist.map((lists, index)=>{
              const {image, op1, op2, op3, op4} = lists;
              return(
                <>
                <div key={index} className="animelists">
                  {index === currentImageNumber && (
                      <>
                      <img src={image} alt="" />
                      <h3>Which anime series/manga is this character from? 🤔</h3>
                      <p style={{color: "red"}}>{error}</p>
                      <div className="buttons">
                        <button onClick={handleClick}>{op1}</button>
                        <button onClick={handleClick}>{op2}</button>
                        <button onClick={handleClick}>{op3}</button>
                        <button onClick={handleClick}>{op4}</button>
                      </div>
                      </>
                  )}

                </div>
                </>
              )
            })):"Not found"}
          </div>
      </div>
    </>
  );
}

export default App;
